/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import * as React from "react"

 
 import Header from "../components/header"
 import Footer from "../components/footer"
 
 const Layout = ( props ) => {
 
   return (
	 <>
		 <Header heading1={props.heading1} heading2={props.heading2} />
			 {props.children}
		 <Footer location={props.location} />
	 </>
   )
 }
 

 export default Layout
 